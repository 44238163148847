import React from "react";
import styled from "styled-components";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

interface Props {
  setVideo: (val: boolean) => void;
  src: string;
}

export default React.memo(function VideoModal({ setVideo, src }: Props) {
  return (
    <Wrapper>
      <VideoBox>
        <Close onClick={() => setVideo(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </Close>
        <Player autoPlay src={src} />
      </VideoBox>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999999999;
  display: flex;
  justify-content: center;
`;

const VideoBox = styled.div`
  position: relative;
  margin-top: 135px;
  height: 736px;
  background-color: #fff;
  width: 1310px;

  .video-react .video-react-big-play-button {
    display: none;
  }
`;

const Close = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  border: none;
  background-color: transparent !important;
  width: 45px;
  border: none;
`;
