export const WHATSAPP_NUMBER: string = "994514076345";

export const SITE_URL: string = "https://promusic.az";

export const SITE_NAME: string = "Promusic.az";

export const defaultLocale: string = "az";

export const loan: {
  month: number;
  percent: number;
}[] = [
  {
    month: 6,
    percent: 9.5,
  },
  {
    month: 9,
    percent: 13.8,
  },
  {
    month: 12,
    percent: 18.2,
  },
  {
    month: 15,
    percent: 24.1,
  },
  {
    month: 18,
    percent: 29,
  },
];
