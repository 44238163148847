import { t } from "@/helper/helper";
import styled from "styled-components";

interface Props {
  locale: string;
}

export default function InfoBanner({ locale }: Props) {
  return (
    <Wrapper>
      <div className="home-info-parent">
        <div className="home-info">
          <div className="home-info_card">
            <div className="top">
              <div className="ball">
                <svg
                  width={42}
                  height={42}
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width={42}
                    height={42}
                    rx={8}
                    fill="url(#paint0_radial)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width={41}
                    height={41}
                    rx="7.5"
                    stroke="url(#paint1_linear)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width={41}
                    height={41}
                    rx="7.5"
                    stroke="url(#paint2_linear)"
                  />
                  <path
                    d="M30.1875 20.6719C30.1875 27.5625 21 32.1562 21 32.1562C21 32.1562 11.8125 27.5625 11.8125 20.6719V12.6328L21 9.1875L25.5938 10.9102"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <circle
                    opacity="0.25"
                    cx="29.5312"
                    cy="29.5312"
                    r="5.90625"
                    fill="url(#paint3_radial)"
                  />
                  <path
                    d="M21 26.25C21 26.25 26.25 23.625 26.25 19.6875V15.0938L21 13.125L15.75 15.0938V19.6875C15.75 23.625 21 26.25 21 26.25Z"
                    fill="url(#paint4_linear)"
                  />
                  <path
                    d="M30.1876 18.6622V16.6525V12.6329L28.4721 11.9896L27.6144 11.668"
                    stroke="#F9EA5C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(21 21) rotate(90) scale(21)"
                    >
                      <stop stopColor="#343538" />
                      <stop offset={1} stopColor="#1B1C1F" />
                    </radialGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1={0}
                      y1={0}
                      x2="20.3438"
                      y2="21.6563"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F9EC64" />
                      <stop offset={1} stopColor="#F8E852" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1={42}
                      y1={42}
                      x2={21}
                      y2={21}
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F8E957" />
                      <stop offset={1} stopColor="#F9EA5D" stopOpacity={0} />
                    </linearGradient>
                    <radialGradient
                      id="paint3_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(29.5312 29.5312) rotate(90) scale(5.90625)"
                    >
                      <stop stopColor="#F8E955" />
                      <stop offset={1} stopColor="#F8E955" stopOpacity={0} />
                    </radialGradient>
                    <linearGradient
                      id="paint4_linear"
                      x1="26.25"
                      y1="14.7656"
                      x2="17.3906"
                      y2="25.9219"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F5E333" />
                      <stop offset={1} stopColor="#FCF079" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h2 className="title">{t("qualityAndGuarantee", locale)}</h2>
            </div>
            <p className="content">
              {t("qualityAndGuaranteeDescription", locale)}
            </p>
          </div>
          <div className="home-info_card">
            <div className="top">
              <div className="ball">
                <svg
                  width={42}
                  height={42}
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width={42}
                    height={42}
                    rx={8}
                    fill="url(#paint0_radial)"
                  />
                  <circle
                    opacity="0.25"
                    cx="29.5312"
                    cy="29.5312"
                    r="5.90625"
                    fill="url(#paint1_radial)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width={41}
                    height={41}
                    rx="7.5"
                    stroke="url(#paint2_linear)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width={41}
                    height={41}
                    rx="7.5"
                    stroke="url(#paint3_linear)"
                  />
                  <path
                    d="M31.4282 19.2968H30.9668V16.0668C30.9668 14.7935 30.0726 13.7257 28.8794 13.4566L26.7792 9.81904C26.4714 9.28595 25.9745 8.90458 25.3799 8.74525C24.96 8.6328 24.5271 8.64106 24.1211 8.76362L22.594 7.23644C21.6954 6.3379 20.2334 6.33781 19.3348 7.23644L13.1807 13.3906H11.8638C10.3881 13.3906 9.1875 14.5911 9.1875 16.0668V27.5102C9.1875 28.9859 10.3881 30.1865 11.8638 30.1865H18.0007C18.2555 30.1865 18.4622 29.9798 18.4622 29.725C18.4622 29.4702 18.2555 29.2636 18.0007 29.2636H11.8638C10.8969 29.2636 10.1104 28.477 10.1104 27.5102V16.0668C10.1104 15.1 10.8969 14.3134 11.8638 14.3134H28.2905C29.2574 14.3134 30.0439 15.1 30.0439 16.0668V19.2968H26.6294C25.2555 19.2968 24.1377 20.4146 24.1377 21.7885C24.1377 23.1624 25.2555 24.2802 26.6294 24.2802H30.0439V27.5102C30.0439 28.477 29.2574 29.2636 28.2905 29.2636H22.1536C21.8988 29.2636 21.6921 29.4702 21.6921 29.725C21.6921 29.9798 21.8988 30.1865 22.1536 30.1865H28.2905C29.7662 30.1865 30.9668 28.9859 30.9668 27.5102V24.2802H31.4282C32.1915 24.2802 32.8125 23.6592 32.8125 22.8959V20.6811C32.8125 19.9178 32.1915 19.2968 31.4282 19.2968ZM25.141 9.63668C25.4975 9.73219 25.7955 9.96083 25.98 10.2805L27.7757 13.3906H17.8297L24.0925 9.77469C24.4121 9.59021 24.7844 9.54112 25.141 9.63668ZM19.9874 7.88903C20.2483 7.62805 20.5953 7.48431 20.9644 7.48431C21.3335 7.48431 21.6805 7.62805 21.9414 7.88903L23.2487 9.1963L15.984 13.3906H14.4859L19.9874 7.88903ZM31.8896 22.8959C31.8896 23.1504 31.6827 23.3574 31.4282 23.3574H26.6294C25.7643 23.3574 25.0605 22.6536 25.0605 21.7885C25.0605 20.9234 25.7643 20.2197 26.6294 20.2197H31.4282C31.6827 20.2197 31.8896 20.4267 31.8896 20.6811V22.8959Z"
                    fill="white"
                  />
                  <path
                    d="M26.5522 21.3262C26.4308 21.3262 26.3118 21.3755 26.226 21.4614C26.1401 21.5472 26.0908 21.6658 26.0908 21.7876C26.0908 21.909 26.1401 22.028 26.226 22.1138C26.3118 22.1996 26.4308 22.249 26.5522 22.249C26.674 22.249 26.7926 22.1997 26.8784 22.1138C26.9647 22.028 27.0136 21.909 27.0136 21.7876C27.0136 21.6662 26.9647 21.5472 26.8784 21.4614C26.7926 21.3755 26.674 21.3262 26.5522 21.3262Z"
                    fill="#F8E955"
                  />
                  <path
                    d="M20.0771 29.2637C19.9558 29.2637 19.8367 29.313 19.7509 29.3989C19.6651 29.4847 19.6157 29.6037 19.6157 29.7251C19.6157 29.8465 19.665 29.9655 19.7509 30.0513C19.8367 30.1371 19.9558 30.1865 20.0771 30.1865C20.1985 30.1865 20.3176 30.1372 20.4033 30.0513C20.4892 29.9655 20.5386 29.8465 20.5386 29.7251C20.5386 29.6037 20.4892 29.4847 20.4033 29.3989C20.3176 29.313 20.1985 29.2637 20.0771 29.2637Z"
                    fill="#F8E955"
                  />
                  <path
                    d="M24.9375 21.6562C24.9375 20.5689 25.8189 19.6875 26.9062 19.6875H31.5C32.2249 19.6875 32.8125 20.2751 32.8125 21V22.3125C32.8125 23.0374 32.2249 23.625 31.5 23.625H26.9062C25.8189 23.625 24.9375 22.7436 24.9375 21.6562Z"
                    stroke="#F8E955"
                    strokeWidth="0.7"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(21 21) rotate(90) scale(21)"
                    >
                      <stop stopColor="#343538" />
                      <stop offset={1} stopColor="#1B1C1F" />
                    </radialGradient>
                    <radialGradient
                      id="paint1_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(29.5312 29.5312) rotate(90) scale(5.90625)"
                    >
                      <stop stopColor="#F8E955" />
                      <stop offset={1} stopColor="#F8E955" stopOpacity={0} />
                    </radialGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1={0}
                      y1={0}
                      x2="20.3438"
                      y2="21.6563"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F9EC64" />
                      <stop offset={1} stopColor="#F8E852" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear"
                      x1={42}
                      y1={42}
                      x2={21}
                      y2={21}
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F8E957" />
                      <stop offset={1} stopColor="#F9EA5D" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h2 className="title">{t("internalDelivery", locale)}</h2>
            </div>
            <p className="content">
              {t("internalDeliveryDescription", locale)}
            </p>
          </div>
          <div className="home-info_card">
            <div className="top">
              <div className="ball">
                <svg
                  width={42}
                  height={42}
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width={42}
                    height={42}
                    rx={8}
                    fill="url(#paint0_radial)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width={41}
                    height={41}
                    rx="7.5"
                    stroke="url(#paint1_linear)"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width={41}
                    height={41}
                    rx="7.5"
                    stroke="url(#paint2_linear)"
                  />
                  <circle
                    opacity="0.25"
                    cx="30.8438"
                    cy="29.5312"
                    r="5.90625"
                    fill="url(#paint3_radial)"
                  />
                  <path
                    d="M14.9827 26.25H12.7952C12.4933 26.25 12.2483 26.005 12.2483 25.7031C12.2483 25.4012 12.4933 25.1562 12.7952 25.1562H14.9827C15.2845 25.1562 15.5295 25.4012 15.5295 25.7031C15.5295 26.005 15.2845 26.25 14.9827 26.25Z"
                    fill="url(#paint4_linear)"
                  />
                  <path
                    d="M32.7584 26.25H31.3912C31.0893 26.25 30.8443 26.005 30.8443 25.7031C30.8443 25.4012 31.0893 25.1562 31.3912 25.1562H32.3045L33.0417 21.2275C33.0318 19.2172 31.3147 17.5 29.2037 17.5H25.6129L23.8728 25.1562H27.0162C27.3181 25.1562 27.5631 25.4012 27.5631 25.7031C27.5631 26.005 27.3181 26.25 27.0162 26.25H23.1881C23.0218 26.25 22.8643 26.1745 22.7604 26.0444C22.6565 25.9153 22.6172 25.7447 22.6543 25.5828L24.6428 16.8328C24.6997 16.5823 24.9206 16.4062 25.1765 16.4062H29.2037C31.9173 16.4062 34.1256 18.6145 34.1256 21.3281L33.2954 25.8037C33.2473 26.063 33.022 26.25 32.7584 26.25Z"
                    fill="url(#paint5_linear)"
                  />
                  <path
                    d="M14.9861 16.4063H10.6111C10.3092 16.4063 10.0642 16.1613 10.0642 15.8594C10.0642 15.5575 10.3092 15.3125 10.6111 15.3125H14.9861C15.288 15.3125 15.533 15.5575 15.533 15.8594C15.533 16.1613 15.288 16.4063 14.9861 16.4063Z"
                    fill="url(#paint6_linear)"
                  />
                  <path
                    d="M14.9827 19.6875H9.51392C9.21204 19.6875 8.96704 19.4425 8.96704 19.1406C8.96704 18.8387 9.21204 18.5938 9.51392 18.5938H14.9827C15.2845 18.5938 15.5295 18.8387 15.5295 19.1406C15.5295 19.4425 15.2845 19.6875 14.9827 19.6875Z"
                    fill="url(#paint7_linear)"
                  />
                  <path
                    d="M14.9844 22.9687H8.42188C8.12 22.9687 7.875 22.7237 7.875 22.4219C7.875 22.12 8.12 21.875 8.42188 21.875H14.9844C15.2863 21.875 15.5313 22.12 15.5313 22.4219C15.5313 22.7237 15.2863 22.9687 14.9844 22.9687Z"
                    fill="url(#paint8_linear)"
                  />
                  <path
                    d="M23.1858 26.25H19.3577C19.0558 26.25 18.8108 26.005 18.8108 25.7031C18.8108 25.4012 19.0558 25.1562 19.3577 25.1562H22.7494L25.2344 14.2188H12.7952C12.4933 14.2188 12.2483 13.9737 12.2483 13.6719C12.2483 13.37 12.4933 13.125 12.7952 13.125H25.9202C26.0864 13.125 26.2439 13.2005 26.3478 13.3306C26.4517 13.4597 26.4911 13.6303 26.4539 13.7922L23.7195 25.8234C23.6627 26.0739 23.4406 26.25 23.1858 26.25Z"
                    fill="url(#paint9_linear)"
                  />
                  <circle cx="17.0625" cy="26.25" r="2.625" fill="#F8E955" />
                  <circle cx="17.0625" cy="26.25" r="2.625" fill="#F8E955" />
                  <circle cx="17.0625" cy="26.25" r="1.3125" fill="#3A4155" />
                  <circle cx="28.875" cy="26.25" r="2.625" fill="#F8E955" />
                  <circle cx="28.875" cy="26.25" r="2.625" fill="#F8E955" />
                  <circle cx="28.875" cy="26.25" r="1.3125" fill="#3A4155" />
                  <defs>
                    <radialGradient
                      id="paint0_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(21 21) rotate(90) scale(21)"
                    >
                      <stop stopColor="#343538" />
                      <stop offset={1} stopColor="#1B1C1F" />
                    </radialGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1={0}
                      y1={0}
                      x2="20.3438"
                      y2="21.6563"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F9EC64" />
                      <stop offset={1} stopColor="#F8E852" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1={42}
                      y1={42}
                      x2={21}
                      y2={21}
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F8E957" />
                      <stop offset={1} stopColor="#F9EA5D" stopOpacity={0} />
                    </linearGradient>
                    <radialGradient
                      id="paint3_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(30.8438 29.5312) rotate(90) scale(5.90625)"
                    >
                      <stop stopColor="#F8E955" />
                      <stop offset={1} stopColor="#F8E955" stopOpacity={0} />
                    </radialGradient>
                    <linearGradient
                      id="paint4_linear"
                      x1="13.8889"
                      y1="25.1562"
                      x2="13.8889"
                      y2="26.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F8EA59" />
                      <stop offset={1} stopColor="#F8E955" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear"
                      x1="28.3831"
                      y1="16.4062"
                      x2="28.3831"
                      y2="26.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F8EA59" />
                      <stop offset={1} stopColor="#F8E955" />
                    </linearGradient>
                    <linearGradient
                      id="paint6_linear"
                      x1="12.7986"
                      y1="15.3125"
                      x2="12.7986"
                      y2="16.4063"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset={1} stopColor="white" />
                    </linearGradient>
                    <linearGradient
                      id="paint7_linear"
                      x1="12.2483"
                      y1="18.5938"
                      x2="12.2483"
                      y2="19.6875"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset={1} stopColor="white" />
                    </linearGradient>
                    <linearGradient
                      id="paint8_linear"
                      x1="11.7031"
                      y1="21.875"
                      x2="11.7031"
                      y2="22.9687"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="white" />
                      <stop offset={1} stopColor="white" />
                    </linearGradient>
                    <linearGradient
                      id="paint9_linear"
                      x1="19.358"
                      y1="13.125"
                      x2="19.358"
                      y2="26.25"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F8EA59" />
                      <stop offset={1} stopColor="#F8E955" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h2 className="title"> {t("suitablePaymentMethods", locale)}</h2>
            </div>
            <p className="content">
              {t("suitablePaymentMethodsDescription", locale)}
            </p>
          </div>
          <div className="home-info_card">
            <div className="top">
              <div className="ball">
                <svg
                  width={42}
                  height={42}
                  viewBox="0 0 42 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 8C0 3.58172 3.58172 0 8 0H34C38.4183 0 42 3.58172 42 8V34C42 38.4183 38.4183 42 34 42H8C3.58172 42 0 38.4183 0 34V8Z"
                    fill="url(#paint0_radial)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34 1H8C4.13401 1 1 4.13401 1 8V34C1 37.866 4.13401 41 8 41H34C37.866 41 41 37.866 41 34V8C41 4.13401 37.866 1 34 1ZM8 0C3.58172 0 0 3.58172 0 8V34C0 38.4183 3.58172 42 8 42H34C38.4183 42 42 38.4183 42 34V8C42 3.58172 38.4183 0 34 0H8Z"
                    fill="url(#paint1_linear)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34 1H8C4.13401 1 1 4.13401 1 8V34C1 37.866 4.13401 41 8 41H34C37.866 41 41 37.866 41 34V8C41 4.13401 37.866 1 34 1ZM8 0C3.58172 0 0 3.58172 0 8V34C0 38.4183 3.58172 42 8 42H34C38.4183 42 42 38.4183 42 34V8C42 3.58172 38.4183 0 34 0H8Z"
                    fill="url(#paint2_linear)"
                  />
                  <path
                    opacity="0.25"
                    d="M36.75 29.5312C36.75 32.7932 34.1057 35.4375 30.8438 35.4375C27.5818 35.4375 24.9375 32.7932 24.9375 29.5312C24.9375 26.2693 27.5818 23.625 30.8438 23.625C34.1057 23.625 36.75 26.2693 36.75 29.5312Z"
                    fill="url(#paint3_radial)"
                  />
                  <path
                    d="M10.3989 25.2396C9.72987 25.2396 9.1875 24.6978 9.1875 24.0282V17.9712C9.1875 17.3021 9.72987 16.7598 10.3989 16.7598C11.0679 16.7598 11.6103 17.3021 11.6103 17.9712V24.0282C11.6103 24.6963 11.0679 25.2396 10.3989 25.2396Z"
                    fill="white"
                  />
                  <path
                    d="M13.9326 28.2694C13.2636 28.2694 12.7212 27.727 12.7212 27.058V14.9419C12.7212 14.2728 13.2636 13.7305 13.9326 13.7305C14.6016 13.7305 15.144 14.2728 15.144 14.9419V27.058C15.1445 27.727 14.6016 28.2694 13.9326 28.2694Z"
                    fill="#F8E955"
                  />
                  <path
                    d="M17.4663 26.755C16.7972 26.755 16.2549 26.2126 16.2549 25.5436V16.4575C16.2549 15.7885 16.7972 15.2461 17.4663 15.2461C18.1353 15.2461 18.6782 15.7885 18.6782 16.4575V25.5436C18.6777 26.2126 18.1353 26.755 17.4663 26.755Z"
                    fill="white"
                  />
                  <path
                    d="M21 29.7842C20.3309 29.7842 19.7885 29.2424 19.7885 28.5728V13.4282C19.7885 12.7592 20.3309 12.2168 21 12.2168C21.669 12.2168 22.2114 12.7592 22.2114 13.4282V28.5723C22.2114 29.2419 21.669 29.7842 21 29.7842Z"
                    fill="white"
                  />
                  <path
                    d="M24.5333 32.8125C23.8647 32.8125 23.3219 32.2701 23.3219 31.6011V10.3989C23.3219 9.72987 23.8647 9.1875 24.5333 9.1875C25.2028 9.1875 25.7447 9.72987 25.7447 10.3989V31.6011C25.7447 32.2701 25.2028 32.8125 24.5333 32.8125Z"
                    fill="#F8E955"
                  />
                  <path
                    d="M28.068 28.2694C27.3985 28.2694 26.8566 27.727 26.8566 27.058V14.9419C26.8566 14.2728 27.3985 13.7305 28.068 13.7305C28.7365 13.7305 29.2794 14.2728 29.2794 14.9419V27.058C29.2794 27.727 28.7365 28.2694 28.068 28.2694Z"
                    fill="white"
                  />
                  <path
                    d="M31.6011 23.7267C30.9321 23.7267 30.3897 23.1844 30.3897 22.5158V19.4868C30.3897 18.8178 30.9321 18.2754 31.6011 18.2754C32.2702 18.2754 32.8126 18.8178 32.8126 19.4868V22.5158C32.8126 23.1844 32.2702 23.7267 31.6011 23.7267Z"
                    fill="white"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(21 21) rotate(90) scale(21)"
                    >
                      <stop stopColor="#343538" />
                      <stop offset={1} stopColor="#1B1C1F" />
                    </radialGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1={0}
                      y1={0}
                      x2="20.3438"
                      y2="21.6563"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F9EC64" />
                      <stop offset={1} stopColor="#F8E852" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear"
                      x1={42}
                      y1={42}
                      x2={21}
                      y2={21}
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F8E957" />
                      <stop offset={1} stopColor="#F9EA5D" stopOpacity={0} />
                    </linearGradient>
                    <radialGradient
                      id="paint3_radial"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(30.8438 29.5312) rotate(90) scale(5.90625)"
                    >
                      <stop stopColor="#F8E955" />
                      <stop offset={1} stopColor="#F8E955" stopOpacity={0} />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <h2 className="title">{t("liveSoundAndImage", locale)}</h2>
            </div>
            <p className="content">
              {t("liveSoundAndImageDescription", locale)}
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .home-info {
    height: auto;
    padding-top: 1.6rem;
    padding-bottom: 2.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 16px;

    @media (max-width: 765px) {
      display: flex !important;
      flex-direction: row;
      flex-wrap: nowrap;
      width: fit-content !important;
      overflow: hidden;
      padding: 36px 0 !important;
      align-items: flex-start;
    }

    &-parent {
      overflow: auto !important;
    }

    &_card {
      height: auto;
      width: 100%;

      @media (max-width: 765px) {
        width: 168px !important;
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        height: 40px;

        .ball {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          background: radial-gradient(
            61.61% 61.61% at 64.29% 38.39%,
            rgba(106, 148, 255, 0.1) 0%,
            rgba(106, 148, 255, 0.5) 100%
          );
          margin-right: 12px;

          svg {
            width: 100%;
            height: 100%;
            width: 4rem;
            height: 4rem;
          }
        }

        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 100%;
          color: #777f95;
        }
      }

      .content {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 150%;
        color: rgba(119, 127, 149, 0.85);
        text-align: center;


        @media(max-width:575px){
          text-align: left;
        }

      }
    }
  }
`;
