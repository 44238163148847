import { commons } from "@/services/requests";
import { ISlider } from "global-types/global.types";
import { useQuery } from "react-query";

interface Props {
  initialData: ISlider;
}

export default function useSlider({ initialData }: Props) {
  const { data, isLoading } = useQuery(
    ["HOMEPAGE_SLIDER"],
    commons.getSliders,
    {
      initialData,
    }
  );

  return { data, isLoading };
}
